
<template>
  <div class="audio-player">
    <!-- :src="require(`${list[index].url}`) closeIcon -->
    <audio controls ref="audio-player"></audio>

    <div class="audio-mock-player" v-if="isShowAudioPlayer">
      <div class="audioInfo">
           <img src="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/%E7%BC%96%E7%BB%84%206%402x.png"/>
           <div class="right-menu">
              <div class="song-name">
                <p>{{ currentInfo && currentInfo.name }}</p>
                <span class="current">{{ currentTime }}</span>
                <span class="duration">/{{ duration }}</span>
              </div>
              <div class="bottom"  @mousedown="ifDraging=true"
                @mouseup="ifDraging=false">
                <!--  播放进度条 -->
                <el-slider
               
                  class="progress"
                  v-model="sliderVal"
                  :format-tooltip="formatTooltip"
                  :min="sliderMin"
                  :max="sliderMax"
                  @change="spliderSelect"
                />
            </div>
          </div>
      </div>
      <div class="control">
          <!-- 上一首 -->
        <!-- <img
          @click="musicPlay('pre')"
          :src="pre"
          class="preicon"
        /> -->
        <i class="iconfont preicon"  @click="musicPlay('pre')">&#xe78a;</i>
        <!-- "暂停" : "播放" -->
        <!-- <img
          @click="musicPlay('play')"
          class="play-sty"
          :src="play ? plozeIcon : playIcon"
        /> -->
        <i class="iconfont play-sty" @click="musicPlay('play')">{{play ? '&#xe613;' : '&#xe624;'}}</i>
        <!-- 下一首 -->
        <i class="iconfont preicon" @click="musicPlay('next')">&#xe7a5;</i>
        <!-- <img @click="musicPlay('next')" :src="next" class="preicon" /> -->
        <p></p>
        <img :src="closeIcon" class="closeicon" @click="closeAudioPlay" />

      </div>
      
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // 显示隐藏
    isShowAudioPlayer: {
      default: true,
    },
      // 播放列表
    list: {
      default: function () {
        return [
          //数据格式
          {
            id: 2,
            name: "起风了4",
            sound_url: "https://media.w3.org/2010/05/sintel/trailer.mp4",
            cover: "ChenYou",
            singer: "林俊杰",
          },
          
        ];
      },
    },
    //  播放索引
    // index
  },
  watch:{
    isShowAudioPlayer(e){
        
    },
  },
  computed:{
     currentInfo(){
      return this.list[this.index]
     }
  },
  data() {
    return {
      ifDraging:false,
      next:require("@/assets/images/next.png"),
      pre:require("@/assets/images/pre.png"),
      playIcon: require("@/assets/images/play.png"),
      plozeIcon: require("@/assets/images/ploze.png"),
      closeIcon: 'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/%E5%85%B3%E9%97%AD26%402x.png',
      box: undefined,
      index: 0, // 当前播放的音乐素质索引
      play: false, // 播放状态，true为正在播放
      sliderVal: 0, // 这个对接当前时长。
      sliderMin: 0,
      sliderMax: 0, // 这个对接总时长。
      duration: undefined, // 音乐总时长
      currentTime: undefined, //当前播放时长
    };
  },
  mounted() {
        
  },
  methods: {
    getData(index){
        this.index = index
        this.init();
    },
    init() {
      console.log(this.list)
      console.log(this.index)
      this.box = this.$refs["audio-player"];
      this.box.src = this.list[this.index].sound_url;
      console.log(this.box, "音频播放器DOM");
      const that = this;

      //  this.duration  =  this.formatTime(this.box.duration)
      // 绑定三个触发方法
      // 当时长有变化时触发，由"NaN"变为实际时长也算
      this.box.ondurationchange = function () {
        console.log("时长发生了变化");
        console.log(that.box.duration);
        // that.
        // that.duration  =  that.formatTime(that.box.duration)
        that.sliderMax = Math.floor(that.box.duration);
        that.updateTime();
      };
      // 当前数据可用是触发
      this.box.oncanplay = function () {
        console.log("已经可以播放了");
        that.play = !that.play
        that.musicPlay('play')
      };
      // 播放位置发送改变时触发。
      this.box.ontimeupdate = function () {
        // console.log("播放位置发送了变动");
        that.updateTime();
      };
      // 音频播放完毕
      this.box.onended = function () {
        console.log("播放完毕，谢谢收听");
      };
      // 音频播放完毕
      this.box.onerror = function () {
        console.log("加载出错！");
      };
    },
 
    // 播放
    musicPlay(flag) {
      switch (flag) {
        case "pre":
          if (this.list[this.index - 1]) {
            this.box.src = this.list[this.index - 1].sound_url;
            this.index -= 1;
          } else {
            this.box.src = this.list[this.list.length - 1].sound_url;
            this.index = this.list.length - 1;
          }
          this.init();
          // if (this.play) {
          //   this.box.play();
          // } else {
          //   this.box.pause();
          // }
          break;
        case "play":
          this.play = !this.play;
          console.log('play')
          if (this.play) {
            this.box.play();
          } else {
            this.box.pause();
          }
          break;
        case "next":
          if (this.list[this.index + 1]) {
            this.box.src = this.list[this.index + 1].sound_url;
            this.index += 1;
          } else {
            this.box.src = this.list[0].sound_url;
            this.index = 0;
          }
          this.init();
          // if (this.play) {
          //   this.box.play();
          // } else {
          //   this.box.pause();
          // }
          break;
      }
    },
    formatTooltip(val) {
      // 格式化毫秒数，由于组件提示为纯数字，所以这里需要将数字转化为我们所需要的的格式，string类型的。'03:45',
      const time = this.formatTime(val);
      return `${time.min}:${time.sec}`;
    },
   
    updateTime() {
      const total = this.formatTime(this.box.duration);
      const current = this.formatTime(this.box.currentTime);
      this.duration = `${total.min}:${total.sec}`;
      this.currentTime = `${current.min}:${current.sec}`;
      // 值为xx.xxxxx 需要取整
      if(!this.ifDraging){
        this.sliderVal = Math.floor(this.box.currentTime);
      }
      
      
    },
    formatTime(time) {
      // 格式化毫秒，返回String型分秒对象

      if (!time) return { min: "00", sec: "00" };
      return {
        min: Math.floor(time / 60)
          .toString()
          .padStart(2, "0"),
        sec: Math.floor(time % 60)
          .toString()
          .padStart(2, "0"),
      };
    },
    closeAudioPlay() {
      this.$emit('closeAudio')
      
    },
    spliderSelect(e) {
      // 滑块松动后触发。更新当前时长，
      // 时长发生变动会init里的方法进行更新数据
      // console.log('----',this.sliderVal)
      // console.log('----',e)
      this.box.currentTime = this.sliderVal;
    },
  },
};
</script>

<style  lang='scss'>
  
  .el-slider__button-wrapper{
    width: 8px;
    height: 8px;
    margin: 0;
    top:-3px;
    .el-slider__button{
      display: block;
    }
  }
  .el-slider__button{
    width: 8px;
    height: 8px;
    border: none;
  }
  .el-slider__runway{
    height: 2px;
  }
  .el-slider__bar{
    height: 2px;
  }
/* CDN 服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */

@font-face {
  font-family: 'iconfont';
  src: url('../assets/css/font_3588579_2hqnindiin9/iconfont.woff2?t=1660530905989') format('woff2'),
       url('../assets/css/font_3588579_2hqnindiin9/iconfont.woff?t=1660530905989') format('woff'),
       url('../assets/css/font_3588579_2hqnindiin9/iconfont.ttf?t=1660530905989') format('truetype');
}
.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.poppervulumn-class {
  min-width: 20px !important;
  padding: 12px 5px !important;
}
.audio-player {
  position: fixed;
  bottom:0;
  left:0;
  width: 100%;
  z-index: 1000;
  height: 60px;
  background: rgba(34, 34, 34, 0.8);
  border: 1px solid rgba(34, 34, 34, 1);
  audio{
    opacity: 0;
    position: absolute;
  }
  .audio-mock-player {
    position: relative;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    
    display: flex;
    align-items: center;
    .audioInfo{
       flex:1;
       display: flex;
       img{
        width: 40px;
        height: 40px;
       }
       .right-menu {
        margin-left: 15px;
        margin-right: 50px;
        padding: 2px 0;
        flex: 1;
        height: 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .song-name{
          display: flex;
          p{
            flex:1
          }
        }
        .bottom {
          display: flex;
          align-items: center;
          .progress {
            flex: 1;
          }
          .current {
            font-family: PingFangSC-Regular;
            font-size: 12px;
            color: #ffffff;
            text-align: left;
            font-weight: 400;
            margin-left: 12px;
          }
          .duration {
            opacity: 0.6;
            font-family: PingFangSC-Regular;
            font-size: 12px;
            color: #ffffff;
            text-align: left;
            font-weight: 400;
            margin-right: 16px;
          }
          .vulumn {
            position: relative;
            img {
              width: 18.5px;
              height: 15px;
            }
            .vulumn-progress {
              position: absolute;
              top: -73px;
              left: -12px;
            }
          }
        }
        .song-name {
          height: 25px;
          font-family: PingFangSC-Regular;
          font-size: 12px;
          color: #d8d8d8;
          text-align: left;
          font-weight: 400;
          p{
            font-size: 16px;
            color: #ffffff;
          }
        }
      }
    }
    .control{
      display: flex;
      align-items: center;
      img,i{
        cursor: pointer;
      }
      p{
        width: 1px;
        height: 50px;
        background: #9e9e9e;
        margin: 0 20px;
      }
    }
    .play-sty {
      margin: 0 16px;
      font-size: 28px;
      color:#e9e9e9
    }
    .preicon {
      font-size:16px;
      color:#e9e9e9
    }
    .closeicon {
      width: 15px;
      height: 15px;
    }
    
  }
}

</style>
