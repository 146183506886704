-<template>
  <div class="lesson" v-loading="listLoading">
    <div class="header">
      <div class="logoAll" @click="$router.push('./index')">
        <img :src="logo" class="logo" alt="" />
      </div>

      <div class="tuoyuSearch">
        <input
          v-model="keywords"
          placeholder="请输入您需要搜索的课程"
          @keyup.enter="getList('-1')"
        />
        <div class="btn" @click="getList(classify)">搜索</div>
      </div>
    </div>
    <div class="right">
      <!-- <div class="idea-nav" style="justify-content: center">
      <template v-for="(item, index) of category">
        <div class="idea-nav-item"  :key="item.id" @click="getList(index)" :class="{ active: classify == index }">
          {{ item.category_name }}
        </div>
      </template>
    </div> -->
      <van-list
        v-if="list.length"
        :finished="finished"
        finished-text="没有更多了"
        style="margin-top: 40px"
      >
        <div class="list">
          <div class="card" v-for="item of list" :key="item.id" @click="jumpDetail(item)">
            <div class="tip">
              <!-- is_vip_flat 判断是否会员商品 0不是 1是  is_free 判断是否限免 2限免，1不限免-->
              <img :src="$free" v-if="item.is_free == 2" />
              <img :src="$vip" v-else-if="item.is_vip_flat == 1" />
              <img :src="$noVip" v-else />
            </div>
            <div class="content">
              <van-image
                class="cover"
                fit="cover"
                :error-icon="$placeImg"
                :loading-icon="$placeImg"
                :src="item.cover_mobile"
              />
              <div class="detail">
                <p class="title">
                  {{ item.name }}
                </p>
                <!-- <p class="text">
                {{ item.description }}
              </p> -->
              </div>
              <div class="handle">
                <div class="btn">立即学习</div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="list" v-if="category[classify].id == 'live'">
          <div class="card" v-for="item of list" :key="item.id" @click="liveplay(item)">
            <div class="content">
              <van-image
                class="cover"
                fit="cover"
                :error-icon="$placeImg"
                :loading-icon="$placeImg"
                :src="item.img_url"
              />
              <div class="detail">
                <p class="title">
                  {{ item.name }}
                </p>
                <p class="text">主讲人：{{ item.host }}</p>
                <p class="text">
                {{ item.description }}
              </p>
              </div>
              <div class="handle">
                <div class="btn">观看回放</div>
              </div>
            </div>
          </div>
        </div> -->
      </van-list>
      <!-- <idea-list v-if="category[classify].id=='idea'" :list="list"></idea-list> -->
      <van-empty class="emtpyMain" v-if="!list.length" description="暂无数据" />
    </div>
  </div>
</template>

<script>
import ideaList from "../components/ideaList.vue";
export default {
  components: { ideaList },
  name: "lesson",
  data() {
    return {
      list: [],
      tag: 1,
      keywords: "",
      classify: 0,
      certificate: [],
      topData: [],
      category: [
        {
          id: "live",
          name: "直播",
        },
        // {
        //   id: "idea",
        //   name: "锦囊",
        // },
      ],
      loading: false,
      pageLoading: false,
      finished: true,
      more: false,
      ideaNavData: [],
      ideaAllData: [],
      listLoading: false,
    };
  },
  mounted() {
    this.keywords = this.$route.query.keywords || "";
    // this.$request.tuoyuCategory2({ flat_id: this.plat }).then((res) => {
    //   this.category = res.data;
    // });
    this.getList();
    window.addEventListener("scroll", this.onLoad);
  },
  destroyed() {
    window.removeEventListener("scroll", this.onLoad);
  },
  computed: {
    plat() {
      return this.$store.state.plat_id;
    },
    name() {
      return this.$store.state.plat.name;
    },
    logo() {
      return this.$store.state.plat.logo;
      // return "https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyulogo.png";
    },
  },
  methods: {
    liveplay(item) {
      let { name, video_url, id, video_id } = item;
      if (video_url || video_id) {
        this.$store.commit("SET_VIDEO", null);
        this.$router.push(
          `./player?type=ali&id=${id}&videoId=${video_id}&name=${name}&url=${encodeURI(
            video_url
          )}&from=直播`
        );
      } else {
        this.$toast("没有视频");
      }

      this.$api({
        method: "get",
        url: "/api/livestream/click",
        data: {
          id,
        },
      });
    },
    async getList(index) {
      if (index != "-1") this.classify = index;
      console.log(this.keywords);
      if (!this.keywords) {
        return;
      }

      this.listLoading = true;
      this.$request
        .tuoyuSearch({
          flat_id: this.plat,
          is_interior: 2,
          keywords: this.keywords,
          page: 1,
          limit: 1000,
        })
        .then((res) => {
          this.listLoading = false;
          if (res.code == 0) {
            this.list = res.data.list;
          } else {
            this.$toast(res.msg);
          }
        });
    },
    jumpDetail(item) {
      this.$router.push(`./detail2?id=${item.id}`);
    },
  },
  beforeRouteUpdate(to, from, next) {
    console.log("beforeRouteUpdate");
    console.log(to);
    next();
  },
};
</script>

<style lang="scss" scoped>
.logoAll {
  display: flex;
  align-items: center;
  img {
    margin-right: 5px;
  }
}
.idea-nav {
  .idea-nav-item:hover {
    color: #fff;
    background: #0080d6;
  }
  .active {
    color: #fff;
    background: #0080d6;
  }
}
.header {
  display: flex;
  justify-content: space-between;
  .search {
    flex: 0 0 400px;
  }
}
.lesson {
  min-height: calc(100vh - 230px);
  display: flex;
  align-items: flex-start;
  overflow: hidden;
  flex-direction: column;
  .van-sticky {
    left: auto;
    right: auto;
  }

  .right {
    width: 860px;
    margin: 40px auto;
  }

  .card {
    background: #fff;
    box-shadow: 0 5px 10px #33333308;
    border-radius: 12px;
    padding: 20px 20px;
    margin-bottom: 20px;
    cursor: pointer;
    position: relative;
    .tip {
      position: absolute;
      top: 20px;
      left: 20px;
      z-index: 1;
      img {
        width: 50px;
        // height: 35px;
      }
    }
    .title {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 10px;
    }

    .content {
      display: flex;
      width: 100%;

      .cover {
        flex: 0 0 280px;
        margin-right: 20px;
        width: 280px;
        height: 136px;
        border-radius: 10px;
        overflow: hidden;
      }

      .detail {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        flex: auto;

        .text {
          font-size: 14px;
          color: #666;
          overflow: hidden;
          display: -webkit-box;
          /*弹性伸缩盒子*/
          -webkit-box-orient: vertical;
          /*子元素垂直排列*/
          -webkit-line-clamp: 5;
          /*可以显示的行数，超出部分用...表示*/
          text-overflow: ellipsis;
          /*（多行文本的情况下，用省略号“…”隐藏溢出范围的文本)*/
        }

        .more {
          font-size: 14px;
          color: #175199;
          display: flex;
          align-items: center;
          margin-top: 10px;
          cursor: pointer;
        }
      }

      .handle {
        margin-left: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 120px;
      }

      .btn {
        cursor: pointer;
        border-radius: 6px;
        line-height: 40px;
        font-weight: 500;
        border-radius: 6px;
        color: #0080d6;
        background: #f7f7f7;
        padding: 0 30px;
        font-size: 14px;

        &:hover {
          color: #fff;
          background: #0080d6;
        }
      }
    }
  }
}
</style>

<style lang="scss"></style>
